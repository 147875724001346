<template>
  <div class="d-flex flex-column justify-content-center text-secondary white-space-no-wrap" v-p-tooltip.bottom="tooltip">
    <slot><span class="text-125 white-space-no-wrap" style="line-height: 1; font-weight: 500;">{{metricValue}}</span></slot>
    <slot name="title">
      <span v-if="title"
            class="title-metric small white-space-no-wrap mt-2">
        {{title}}
        <conversion-breakdown v-if="title === 'Conversions'" :item="item" :title="title" :metric="metric" class="d-inline"/>
      </span>
    </slot>
  </div>
</template>

<script>
import ConversionBreakdown from '@/components/pages/manage/conversionBreakdown'
export default {
  name: 'metricsSettingsPanelItem',
  components: { ConversionBreakdown },
  props: ['title', 'metric', 'lastBudgetItem', 'isFirst', 'item', 'tooltip'],
  computed: {
    metricValue () {
      return this.metric || '--'
    }
  }
}
</script>
